import React from 'react';
import styled from 'styled-components';
import { Color } from '../../assets/constants';
import { icons } from '../../assets/icons';
import { Button, ButtonSize, ButtonType } from '../../components/common/Button';
import { IconButton } from '../../components/common/IconButton';
import { RatingStars } from '../../components/features/details/RatingStars';
import { SizeSelector } from '../../components/features/details/SizeSelector';
import { cartActions } from '../../store/cartSlice';
import {
  Product,
  ProductType,
  TShirtSize,
  TShirtType
} from '../../domain/models/ProductDTO';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { toast } from 'react-toastify';
import { ColorSelector } from '../../components/features/details/ColorSelector';
import { TShirtColor } from '../adminPanel/utils';
import { ActivityIndicator } from '../../components/common/ActivityIndicator';
import { translateTypeToBulgarian } from '../../components/features/cart/utils';
import {
  Modal,
  ModalEnterAnimation,
  ModalExitAnimation
} from '../../components/common/Modal';
import { Cart } from '../../components/features/cart/Cart';
import { CartButton } from '../../components/features/cart/CartButton';
import { Footer } from '../../components/features/home/Footer';
import { ReactComponent as ShippingButton } from '../../assets/images/shipping.svg';
import { ReactComponent as MaterialsButton } from '../../assets/images/materials.svg';
import { useModalClose } from '../../hooks/useModalClose';
import { MaterialsInfoModalContainer } from '../info/MaterialsInfoModalContainer';
import { ShippingInfoModalContainer } from '../info/ShippingInfoModalContainer';
import { CustomizationArea } from '../../components/features/details/CustomizationArea';
import { getAvailableSizes, getImageAltText, TShirtSliderImage } from './utils';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useFavorites } from '../../hooks/useFavorites';
import { AppConfig } from '../../hooks/useAppConfig';
import { isDev } from '../../utils/environment';
import { TShirtSwiper } from '../../components/features/details/TShirtSwiper';

interface Props {
  product: Product;
  images: TShirtSliderImage[];
  tShirtTypes: TShirtType[];
  discountedPrice: number | null;
  selectedType: TShirtType | null;
  onSelectType: (type: TShirtType) => void;
  selectedSize: TShirtSize | null;
  onSelectSize: (size: TShirtSize | null) => void;
  selectedColor: TShirtColor | null;
  onSelectColor: (color: TShirtColor, isMobile?: boolean) => void;
  customizationNotes: string;
  onCustomizationNotesChange: (notes: string) => void;
  imageHasLoaded: boolean;
  onImageLoad: () => void;
  onGoBack: () => void;
  onShowSizeInfo: () => void;
  showSizeInfo: boolean;
  onShowShippingInfo: () => void;
  showShippingInfo: boolean;
  onShowMaterialsInfo: () => void;
  showMaterialsInfo: boolean;
  onToggleCart: () => void;
  showCart: boolean;
  appConfig: AppConfig;
}

export const DetailsContainerMobile = ({
  images,
  tShirtTypes,
  selectedType,
  onSelectType,
  selectedSize,
  onSelectSize,
  selectedColor,
  onSelectColor,
  customizationNotes,
  onCustomizationNotesChange,
  imageHasLoaded,
  onImageLoad,
  onGoBack,
  onShowSizeInfo,
  showSizeInfo,
  onToggleCart,
  showCart,
  onShowShippingInfo,
  showShippingInfo,
  onShowMaterialsInfo,
  showMaterialsInfo,
  product,
  discountedPrice,
  appConfig
}: Props) => {
  const { getIsFavorite, addToFavorites, removeFromFavorites } = useFavorites();
  const cartItems = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const { closing: sizeInfoClosing, handleClose: handleCloseSizeInfo } =
    useModalClose(() => onShowSizeInfo());
  const { closing: shippingInfoClosing, handleClose: handleCloseShippingInfo } =
    useModalClose(() => onShowShippingInfo());
  const {
    closing: materialsInfoClosing,
    handleClose: handleCloseMaterialsInfo
  } = useModalClose(() => onShowMaterialsInfo());

  const imageUrl =
    product.type === ProductType.TSHIRT
      ? // @ts-ignore
        product.images[selectedType][selectedColor].url
      : product.image?.url;
  const imageName = getImageAltText(
    product.type === ProductType.TSHIRT
      ? // @ts-ignore
        product.images[selectedType][selectedColor].name
      : // @ts-ignore
        product.image.name
  );

  const {
    transactions: { areDisabled }
  } = appConfig;
  const areTransactionsDisabled = areDisabled && !isDev();

  const isMug = product.type === ProductType.MUG;
  const isFavorite = getIsFavorite(product);

  const favoriteHandler = () => {
    isFavorite ? removeFromFavorites(product) : addToFavorites(product);
  };

  const addToCartHandler = () => {
    if (product.type === ProductType.TSHIRT && !selectedColor) {
      return toast.error('Моля изберете цвят за вашата тениска.');
    }
    if (product.type === ProductType.TSHIRT && !selectedSize) {
      return toast.error('Моля изберете размер за вашата тениска.');
    }

    dispatch(
      cartActions.addToCart({
        product,
        selectedColor,
        image: imageUrl,
        selectedSize,
        discountedPrice,
        selectedType,
        customizationNotes
      })
    );

    toast.success(`Тениската беше успешно добавена в кошницата.`, {
      icon: (
        <PartyIcon src={require('../../assets/images/party-popperIcon.png')} />
      ),
      onClick: () => {
        onToggleCart();
        toast.dismiss();
      }
    });
  };

  const cartItemsQuantity = cartItems.length;

  return (
    <>
      <Cart
        cartItems={cartItems}
        showCart={showCart}
        onCloseCart={onToggleCart}
      />
      <CartButton
        onOpenCart={onToggleCart}
        itemsQuantity={cartItemsQuantity}
        disabled={areTransactionsDisabled}
      />
      <Container>
        <ActionButtonsWrapper>
          <IconButton icon={icons.FaChevronLeft} onClick={onGoBack} />
        </ActionButtonsWrapper>
        <ImageWrapper>
          {!isMug ? (
            <TShirtSwiper
              images={images}
              selectedColor={selectedColor}
              onSelectColor={onSelectColor}
            />
          ) : (
            <>
              {!imageHasLoaded && (
                <ActivityIndicator size={100} color={Color.ACCENT} />
              )}
              <Zoom
                swipeToUnzoomThreshold={200}
                zoomMargin={-10}
                IconUnzoom={() => <icons.FaTimes size={22} />}
              >
                <Image
                  src={imageUrl}
                  alt={getImageAltText(imageName)}
                  onLoad={() => onImageLoad()}
                  loaded={imageHasLoaded}
                />
              </Zoom>
            </>
          )}
        </ImageWrapper>
        <BottomSheetContainer>
          <BottomSheetContent>
            <HeaderWrapper>
              <RatingStars />
              <TitleWrapper>
                <Title>{product.title}</Title>
              </TitleWrapper>
              <Description>{product.description}</Description>
            </HeaderWrapper>

            {product.type !== ProductType.MUG && (
              <>
                <SelectTitle>Изберете модел</SelectTitle>
                <TypeSelector>
                  {tShirtTypes.map((type, index) => (
                    <TypeButton
                      onClick={() => onSelectType(type)}
                      selected={selectedType === type}
                      key={index}
                    >
                      {translateTypeToBulgarian(type)}
                    </TypeButton>
                  ))}
                </TypeSelector>

                <SelectTitle>Изберете цвят</SelectTitle>
                <TilesWrapper>
                  {selectedType && (
                    <ColorSelector
                      colors={product.images[selectedType]}
                      selectedColor={selectedColor}
                      onSelectColor={(color) => onSelectColor(color, true)}
                    />
                  )}
                </TilesWrapper>
                <SizeWrapper onClick={onShowSizeInfo}>
                  <SelectSizeTitle>Изберете размер</SelectSizeTitle>
                  <SizeChartButton
                    src={require('../../assets/images/size-chart.png')}
                  />
                </SizeWrapper>
                <TilesWrapper>
                  {selectedType === TShirtType.MEN && (
                    <SizeSelector
                      availableSizes={getAvailableSizes(
                        product.sizes,
                        selectedColor,
                        TShirtType.MEN
                      )}
                      selectedSize={selectedSize}
                      onSelectSize={(size) => onSelectSize(size)}
                    />
                  )}
                  {selectedType === TShirtType.WOMEN && (
                    <SizeSelector
                      availableSizes={getAvailableSizes(
                        product.sizes,
                        selectedColor,
                        TShirtType.WOMEN
                      )}
                      selectedSize={selectedSize}
                      onSelectSize={(size) => onSelectSize(size)}
                    />
                  )}
                  {selectedType === TShirtType.KIDS && (
                    <SizeSelector
                      availableSizes={getAvailableSizes(
                        product.sizes,
                        selectedColor,
                        TShirtType.KIDS
                      )}
                      selectedSize={selectedSize}
                      onSelectSize={(size) => onSelectSize(size)}
                    />
                  )}
                  {selectedType === TShirtType.OVERSIZED && (
                    <SizeSelector
                      availableSizes={getAvailableSizes(
                        product.sizes,
                        selectedColor,
                        TShirtType.OVERSIZED
                      )}
                      selectedSize={selectedSize}
                      onSelectSize={(size) => onSelectSize(size)}
                    />
                  )}
                </TilesWrapper>
              </>
            )}

            <CustomizationArea
              productType={product.type}
              value={customizationNotes}
              onChange={onCustomizationNotesChange}
            />

            <CtaWrapper>
              <PriceWrapper>
                <Price discounted={!!discountedPrice}>{product.price}лв</Price>
                {discountedPrice && (
                  <DiscountedPrice>{discountedPrice}лв</DiscountedPrice>
                )}
              </PriceWrapper>
              <ButtonsWrapper>
                {!areTransactionsDisabled && (
                  <Button
                    label="Добави в кошницата"
                    size={ButtonSize.MEDIUM}
                    onClick={addToCartHandler}
                    additionalStyles={`
                    padding-left: 10px;
                    padding-right: 10px;
                  `}
                  />
                )}
                <Button
                  label={
                    areTransactionsDisabled
                      ? `${isFavorite ? 'Премахни от' : 'Добави в'} любими`
                      : ''
                  }
                  icon={{
                    type: isFavorite ? icons.FaHeart : icons.FaRegHeart,
                    size: 20,
                    color: isFavorite ? Color.RED : Color.GRAY
                  }}
                  size={ButtonSize.MEDIUM}
                  type={ButtonType.UNSELECTED}
                  onClick={favoriteHandler}
                  additionalStyles={`
                    min-width: 0;
                    padding-right: 15px;
                  `}
                />
              </ButtonsWrapper>
            </CtaWrapper>
          </BottomSheetContent>
          <ShippingAndMaterialsWrapper>
            <FullWidthButton onClick={() => onShowShippingInfo()}>
              <ShippingButton />
            </FullWidthButton>
            <FullWidthButton onClick={() => onShowMaterialsInfo()}>
              <MaterialsButton />
            </FullWidthButton>
          </ShippingAndMaterialsWrapper>
          <Footer />
        </BottomSheetContainer>
      </Container>
      {showSizeInfo && (
        <Modal
          closing={sizeInfoClosing}
          onClose={handleCloseSizeInfo}
          enterAnimation={ModalEnterAnimation.SLIDE_DOWN_CENTER}
          exitAnimation={ModalExitAnimation.SLIDE_RIGHT_CENTER}
          additionalStyles={`
            width: 90%;
          `}
        >
          <InfoModalWrapper>
            <img src={require('../../assets/images/size-info.png')} />
          </InfoModalWrapper>
        </Modal>
      )}
      {showShippingInfo && (
        <Modal
          closing={shippingInfoClosing}
          onClose={handleCloseShippingInfo}
          exitAnimation={ModalExitAnimation.SLIDE_UP}
          backButton
        >
          <ShippingInfoModalContainer />
        </Modal>
      )}
      {showMaterialsInfo && (
        <Modal
          closing={materialsInfoClosing}
          onClose={handleCloseMaterialsInfo}
          exitAnimation={ModalExitAnimation.SLIDE_UP}
          backButton
        >
          <MaterialsInfoModalContainer />
        </Modal>
      )}
    </>
  );
};

const PartyIcon = styled.img`
  width: 24px;
`;

const FullWidthButton = styled.div`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 2px solid ${Color.WHITE};
  &:first-child {
    border-right: 1px solid ${Color.WHITE};
  }
  &:last-child {
    border-left: 1px solid ${Color.WHITE};
  }
  &:hover {
    backdrop-filter: brightness(0.8);
  }
`;

const ShippingAndMaterialsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Color.ACCENT};
`;

const SizeChartButton = styled.img`
  cursor: pointer;
  width: 40px;
`;

const InfoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SizeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
`;

const TypeButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 5px 10px;
  min-height: 48px;
  background: ${(props) => (props.selected ? Color.ACCENT : Color.LIGHT_GRAY)};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
  flex-grow: 1;
  color: ${(props) => (props.selected ? Color.BLACK : Color.GRAY)};

  &:active {
    scale: 0.95;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const PriceWrapper = styled.div``;

const TypeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  height: 350px;

  @media (min-width: 520px) {
    height: 100%;
  }
`;

const Image = styled.img<{ loaded: boolean }>`
  max-width: 100%;
  scale: 0.8;
  transform: translateY(20px);
  ${({ loaded }) => !loaded && 'display: none;'}
`;

const SelectTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
  color: ${Color.DARK_GRAY};
`;

const SelectSizeTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${Color.DARK_GRAY};
`;

const DiscountedPrice = styled.p`
  font-size: 26px;
  font-weight: 800;
  color: ${Color.RED};
`;

const Price = styled.p<{ discounted?: boolean }>`
  font-size: 26px;
  font-weight: 800;
  ${({ discounted }) =>
    discounted &&
    `
      font-size: 20px;
      color: ${Color.GRAY};
      text-decoration: line-through;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const TilesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  line-height: 30px;
`;

const Description = styled.p`
  color: ${Color.GRAY};
  margin-top: 10px;
  line-height: 1.4rem;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const BottomSheetContent = styled.div`
  padding: 0 1rem 1rem 1rem;

  @media (min-width: 520px) {
    padding: 1.5rem;
  }
`;

const BottomSheetContainer = styled.div`
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.75);
`;

const ActionButtonsWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 1000;
`;

const Container = styled.div`
  overflow-x: hidden;
`;
