import React, { useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../../assets/constants';
import { icons } from '../../../assets/icons';
import { Product, ProductType } from '../../../domain/models/ProductDTO';
import { IconButton } from '../../common/IconButton';
import { getDiscountedPrice } from '../../../containers/adminPanel/utils';
import { productAppearAnimation } from '../../../utils/animations';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { useFavorites } from '../../../hooks/useFavorites';

interface Props {
  product: Product;
  discount?: number;
  onSelectProductToEdit?: (productId: string) => void;
}

export const ProductCard = ({
  product,
  discount,
  onSelectProductToEdit
}: Props) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const { getIsFavorite, addToFavorites, removeFromFavorites } = useFavorites();
  const navigate = useCustomNavigate();

  const discountedPrice = getDiscountedPrice(product.price, discount);

  const navigateToDetails = (productId: string) => {
    navigate(`/products/${productId}`, {
      state: { discountedPrice }
    });
  };

  const isMug = product.type === ProductType.MUG;
  const isFavorite = getIsFavorite(product);

  const handleFavoriteClick = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    isFavorite ? removeFromFavorites(product) : addToFavorites(product);
  };

  return (
    <Card
      onClick={() =>
        onSelectProductToEdit
          ? onSelectProductToEdit(product.id)
          : navigateToDetails(product.id)
      }
    >
      <ImageWrapper>
        {!imageHasLoaded && (
          <ActivityIndicator size={75} color={Color.ACCENT} />
        )}
        <StyledImage
          src={product.thumbnail.url}
          alt={product.thumbnail.name.replace('.png', '')}
          isMug={isMug}
          onLoad={() => setImageHasLoaded(true)}
          loaded={imageHasLoaded}
        />
      </ImageWrapper>

      <div>
        <Title>{product.title}</Title>
        <FavoriteButton>
          <PriceWrapper>
            <Price discounted={!!discount}>{product.price}лв</Price>
            {discount && <DiscountedPrice>{discountedPrice}лв</DiscountedPrice>}
          </PriceWrapper>
          {onSelectProductToEdit && <IconButton icon={icons.FaEdit} />}
          {!onSelectProductToEdit && isFavorite && (
            <IconButton icon={icons.FcLike} onClick={handleFavoriteClick} />
          )}
          {!onSelectProductToEdit && !isFavorite && (
            <IconButton icon={icons.FaRegHeart} onClick={handleFavoriteClick} />
          )}
        </FavoriteButton>
      </div>
    </Card>
  );
};

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.h1<{ discounted?: boolean }>`
  ${({ discounted }) =>
    discounted &&
    `
      font-size: 14px;
      text-decoration: line-through;
      color: ${Color.GRAY}
  `}
`;

const DiscountedPrice = styled.h1`
  color: ${Color.RED};
  font-size: 18px;
`;

const StyledImage = styled.img<{ isMug: boolean; loaded: boolean }>`
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  object-fit: cover;
  border-radius: 10px;
  ${({ isMug }) => isMug && 'scale: 0.85;'}
  ${({ loaded }) => !loaded && 'display: none;'}
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Color.WHITE};
  border-radius: 10px;
  padding: 0 0.5rem 0.5rem 0.5rem;

  cursor: pointer;

  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  :hover {
    transform: scale(1.05);
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
  }
  transition: ease-out 0.2s;

  h1 {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  ${productAppearAnimation}

  @media (min-width: 768px) {
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    height: 100%;
  }
`;

const FavoriteButton = styled.div`
  display: flex;
  justify-content: space-between;
  bottom: 0.5rem;
  right: 0.5rem;
  h1 {
    margin-top: auto;
  }
`;

const Title = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${Color.DARK_GRAY};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @media (min-width: 1366px) {
    font-size: 1rem;
  }
`;
