import React from 'react';
import { ScreenSize, useScreenSize } from '../../hooks/useScreenSize';
import {
  Product,
  TShirtSize,
  TShirtType
} from '../../domain/models/ProductDTO';
import { TShirtColor } from '../adminPanel/utils';
import { DetailsContainerDesktop } from './DetailsContainerDesktop';
import { DetailsContainerMobile } from './DetailsContainerMobile';
import { AppConfig } from '../../hooks/useAppConfig';
import { TShirtSliderImage } from './utils';

export interface Props {
  product: Product;
  images: TShirtSliderImage[];
  tShirtTypes: TShirtType[];
  discountedPrice: number | null;
  selectedType: TShirtType | null;
  onSelectType: (type: TShirtType) => void;
  selectedSize: TShirtSize | null;
  onSelectSize: (size: TShirtSize | null) => void;
  selectedColor: TShirtColor | null;
  onSelectColor: (color: TShirtColor, isMobile?: boolean) => void;
  customizationNotes: string;
  onCustomizationNotesChange: (notes: string) => void;
  imageHasLoaded: boolean;
  onImageLoad: () => void;
  onGoBack: () => void;
  onShowSizeInfo: () => void;
  showSizeInfo: boolean;
  onShowShippingInfo: () => void;
  showShippingInfo: boolean;
  onShowMaterialsInfo: () => void;
  showMaterialsInfo: boolean;
  onToggleCart: () => void;
  showCart: boolean;
  appConfig: AppConfig;
}

export const DetailsContainer = ({ ...props }: Props) => {
  const screenSize = useScreenSize();

  return screenSize !== ScreenSize.SMALL ? (
    <DetailsContainerDesktop {...props} />
  ) : (
    <DetailsContainerMobile {...props} />
  );
};
