import { OrderShippingInfo } from './../components/features/checkout/utils';
import { useState } from 'react';
import { CartProduct } from '../domain/mappers/cartProductMapper';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc
} from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { toast } from 'react-toastify';
import { PromoCode } from './usePromoCodes';

const { REACT_APP_TENISKI_API_BASE_URL } = process.env;

export enum OrderStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED'
}

export interface MyPosSuccessData {
  OrderID: string;
  RequestSTAN: string;
  RequestDateTime: string;
  IPC_Trnref: number;
}

interface MyPosData extends MyPosSuccessData {
  Amount: string;
  Currency: string;
  BillingDescriptor: string;
  SID: string;
}

export interface Order {
  id: string;
  orderNumber: string;
  status: OrderStatus;
  createdAt: string;
  shippingInfo: OrderShippingInfo & {
    shippingCost: number;
    minimumAmount: number;
  };
  items: CartProduct[];
  promoCode: PromoCode | null;
  myPosData: MyPosData;
  haveItemsBeenAdded?: boolean;
  isAdminEmailSent?: boolean;
  isCustomerOrderCreatedEmailSent?: boolean;
  isCustomerOrderSentEmailSent?: boolean;
}

export const useOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createOrder = async (
    orderId: string,
    items: CartProduct[],
    orderShippingInfo: OrderShippingInfo & {
      shippingCost: number;
      minimumAmount: number;
    },
    promoCode: PromoCode | null
  ) => {
    setIsLoading(true);

    try {
      const res = await fetch(
        `${REACT_APP_TENISKI_API_BASE_URL}/orders/createOrder`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ orderId, items, orderShippingInfo, promoCode })
        }
      );
      const { orderNumber } = await res.json();

      return orderNumber;
    } catch (e: unknown) {
      console.log(`Error adding items to order: ${(e as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllOrders = async () => {
    setIsLoading(true);

    const ordersCollectionRef = collection(db, 'orders');

    try {
      const data = await getDocs(ordersCollectionRef);
      const orders = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      })) as Order[];

      return orders;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(`💥 ${e.message}`);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const getOrderById = async (orderId: string) => {
    setIsLoading(true);

    const docRef = doc(db, 'orders', orderId);

    try {
      const orderDoc = await getDoc(docRef);

      if (orderDoc.exists()) {
        const order = { ...orderDoc.data(), id: orderDoc.id } as Order;

        return order;
      }
    } catch (e: unknown) {
      toast.error(`Error getting order: ${(e as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async () => {
    const productsRef = collection(db, 'orders');

    const querySnapshot = await getDocs(productsRef);

    if (querySnapshot.empty) {
      console.log('No products found.');
      return;
    }

    const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
      const product = docSnapshot.data() as Order;

      // Add condition to target specific items if needed
      console.log('Updating order:', product.id);

      const updatedData = {
        ...product,
        status: OrderStatus.PENDING
      };

      const docRef = doc(db, 'orders', docSnapshot.id);
      return updateDoc(docRef, updatedData);
    });

    await Promise.all(updatePromises);

    console.log('All products updated successfully.');
  };

  return { createOrder, getAllOrders, getOrderById, isLoading, update };
};
