import React, { useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../../assets/constants';
import { cartActions } from '../../../store/cartSlice';
import { CartProduct } from '../../../domain/mappers/cartProductMapper';
import { useAppDispatch } from '../../../hooks/useRedux';
import { translateColorToBulgarian, translateTypeToBulgarian } from './utils';
import { RiCloseFill } from 'react-icons/ri';
import { ActivityIndicator } from '../../common/ActivityIndicator';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { icons } from '../../../assets/icons';
import { Divider } from '../../common/Divider';
import { TShirtColor } from '../../../containers/adminPanel/utils';
import { useLocation } from 'react-router';
import { ProductType } from '../../../domain/models/ProductDTO';

interface Props {
  product: CartProduct;
  removeButton?: boolean;
}

export const CartProductCard = ({ product, removeButton }: Props) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [showCustomization, setShowCustomization] = useState(false);
  const navigate = useCustomNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const navigateToDetails = (productId: string) => {
    navigate(`/products/${productId}`, {
      state:
        product.productType === ProductType.TSHIRT
          ? { color: product.color, type: product.type }
          : null,
      replace: true
    });

    if (location.pathname.includes('/products')) {
      // @ts-ignore
      navigate(0);
    }
  };

  const toggleCustomization = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowCustomization((state) => !state);
  };

  const removeFromCart = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(cartActions.removeFromCart({ product, remove: true }));
  };

  const isMug = product.productType === ProductType.MUG;

  const productColor = isMug ? TShirtColor.WHITE : product.color;

  return (
    <Wrapper>
      <Card onClick={() => navigateToDetails(product.id)}>
        <ImageWrapper>
          {!imageHasLoaded && (
            <ActivityIndicator size={100} color={Color.ACCENT} />
          )}
          <Image
            src={product.image}
            isMug={isMug}
            onLoad={() => setImageHasLoaded(true)}
            loaded={imageHasLoaded}
          />
        </ImageWrapper>
        <ProductDetails>
          <ProductTitle>{product.title}</ProductTitle>

          {removeButton && (
            <RemoveButton onClick={(e) => removeFromCart(e)}>
              <RiCloseFill color={Color.GRAY} size={24} />
            </RemoveButton>
          )}

          <Divider
            additionalStyles={`
              width: 90%; 
              align-self: center;
            `}
          />

          <Column>
            <TextRow>
              <Text>Цвят:</Text>
              <TextRow>
                <ColorCircle color={productColor} />
                <BoldText>{translateColorToBulgarian(productColor)}</BoldText>
              </TextRow>
            </TextRow>
            {product.productType === ProductType.TSHIRT && (
              <>
                <TextRow>
                  <Text>Модел:</Text>
                  <BoldText>{translateTypeToBulgarian(product.type)}</BoldText>
                </TextRow>
                <TextRow>
                  <Text>Размер:</Text>
                  <BoldText>{product.size}</BoldText>
                </TextRow>
              </>
            )}
            <TextRow>
              <Text>Персонализация:</Text>
              {product.customizationNotes ? (
                <CustomizationButton onClick={(e) => toggleCustomization(e)}>
                  <BoldText>Виж</BoldText>
                  <ChevronIcon isOpen={showCustomization}>
                    <icons.FaChevronDown />
                  </ChevronIcon>
                </CustomizationButton>
              ) : (
                <BoldText>Няма</BoldText>
              )}
            </TextRow>
            <TextRow>
              <Text>Цена:</Text>
              <BoldText>{product.price}лв</BoldText>
            </TextRow>
          </Column>
        </ProductDetails>
      </Card>
      <CustomizationNotes
        isOpen={showCustomization}
        isMug={product.productType === ProductType.MUG}
        onClick={(e) => {
          e.stopPropagation();
          setShowCustomization(false);
        }}
      >
        <p>{product.customizationNotes}</p>
      </CustomizationNotes>
    </Wrapper>
  );
};

const ColorCircle = styled.div<{ color: TShirtColor }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};

  ${({ color }) =>
    color === TShirtColor.BLACK ||
    color === TShirtColor.BLUE ||
    color === TShirtColor.DARK_BLUE ||
    color === TShirtColor.LIGHT_BLUE ||
    color === TShirtColor.LIGHT_PINK ||
    color === TShirtColor.RED
      ? ''
      : 'border: 1px solid black;'}
`;

const ChevronIcon = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0deg')});
`;

const CustomizationNotes = styled.div<{ isOpen: boolean; isMug: boolean }>`
  position: absolute;
  z-index: 100;
  top: ${({ isMug }) => (isMug ? 110 : 160)}px;
  right: 10px;
  max-width: 80%;
  background: ${Color.WHITE};
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transform: translateY(-10px) scale(0.95);
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  word-break: keep-all;

  ${({ isOpen }) =>
    isOpen &&
    `
      opacity: 1;
      transform: translateY(0) scale(1);
      pointer-events: all;
  `}
`;

const CustomizationButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img<{ isMug: boolean; loaded: boolean }>`
  transform: scale(1.6);
  ${({ isMug }) => isMug && 'transform: scale(1);'}
  ${({ loaded }) => !loaded && 'display: none;'}
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;

const Text = styled.p`
  color: ${Color.GRAY};
  font-size: 0.9rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TextRow = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BoldText = styled.p`
  font-weight: bold;
  font-size: 0.9rem;
`;

const Card = styled.div`
  cursor: pointer;
  min-width: 385px;
  min-height: 190px;
  display: flex;
  flex-direction: row;
  background-color: ${Color.WHITE};
  gap: 0.3rem;
  border-radius: 10px;
  padding: 0.5rem;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15));
  &:hover {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
  }

  transition: ease-out 0.2s;
  img {
    height: 100px;
    background-size: cover;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const ProductTitle = styled.p`
  margin-right: 20px;
  height: 2.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-left: 1rem;
`;

const Wrapper = styled.div`
  position: relative;
`;
