import {
  ProductType,
  TShirtSize,
  TShirtType
} from '../domain/models/ProductDTO';
import {
  CartProduct,
  CartTShirtProduct,
  mapMugToCartProduct,
  mapTShirtToCartProduct
} from '../domain/mappers/cartProductMapper';
import { createSlice } from '@reduxjs/toolkit';
import { getLocalCartItems, cartItemExists } from './utils';

export interface LocalTShirtItem {
  id: string;
  color: string;
  image: string;
  size: TShirtSize;
  type: TShirtType;
  customizationNotes?: string;
}

export interface LocalMugItem {
  id: string;
  image: string;
  customizationNotes?: string;
}

export type LocalItem = LocalTShirtItem | LocalMugItem;

export type InitialState = CartProduct[];

const initialState: InitialState = [];

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    fillCart: (state, { payload }) => {
      Object.assign(state, payload);
    },
    addToCart: (state, { payload }) => {
      let product: CartProduct;
      if (payload.product.type === ProductType.TSHIRT) {
        product = mapTShirtToCartProduct(
          payload.product,
          payload.selectedColor,
          payload.image,
          payload.selectedSize,
          payload.selectedType,
          payload.customizationNotes
        );
      } else {
        product = mapMugToCartProduct(
          payload.product,
          payload.image,
          payload.customizationNotes
        );
      }

      const localItems = getLocalCartItems();

      let localItem: LocalItem;
      if (payload.product.type === ProductType.TSHIRT) {
        const tShirtProduct = product as CartTShirtProduct;
        localItem = {
          id: product.id,
          color: tShirtProduct.color,
          image: product.image,
          size: tShirtProduct.size,
          type: tShirtProduct.type,
          customizationNotes: product.customizationNotes
        };
      } else {
        localItem = {
          id: product.id,
          image: product.image,
          customizationNotes: product.customizationNotes
        };
      }

      state.push(product);
      localItems.push(localItem);
      localStorage.setItem('cartItems', JSON.stringify(localItems));
    },
    removeFromCart: (state, { payload }) => {
      const localItems = getLocalCartItems();
      const stateIndex = state.findIndex((item) =>
        cartItemExists(item, payload.product)
      );

      state.splice(stateIndex, 1);
      const localItemIndex = localItems.findIndex((item) =>
        cartItemExists(item, payload.product)
      );
      localItems.splice(localItemIndex, 1);
      localStorage.setItem('cartItems', JSON.stringify(localItems));
    },
    clearCart: (state) => {
      state.splice(0, state.length);
      localStorage.setItem('cartItems', JSON.stringify([]));
    }
  }
});

export const cartActions = cartSlice.actions;
