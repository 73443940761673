import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../../assets/constants';
import { icons } from '../../../assets/icons';
import { Textarea } from '../../common/Textarea';
import { ProductType } from '../../../domain/models/ProductDTO';

interface Props {
  productType: ProductType;
  value: string;
  onChange: (notes: string) => void;
}

export const CustomizationArea = ({ productType, value, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  const toggleAccordion = () => setIsOpen((state) => !state);

  return (
    <Wrapper>
      <CustomizeButton onClick={toggleAccordion}>
        Персонализирай
        <ChevronIcon isOpen={isOpen}>
          <icons.FaChevronDown color={Color.DARK_GRAY} size={18} />
        </ChevronIcon>
      </CustomizeButton>
      <TextareaWrapper ref={contentRef} isOpen={isOpen} height={contentHeight}>
        <Textarea
          value={value}
          placeholder={`Какво искаш да променим по ${
            productType === ProductType.TSHIRT ? 'тениската' : 'чашата'
          }? (Текст, цвят на текста и т.н. За пълна персонализация, моля свържете се чрез имейл или телефон.)`}
          onChange={(e) => onChange(e.target.value)}
          maxLength={200}
        />
      </TextareaWrapper>
    </Wrapper>
  );
};

const ChevronIcon = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0deg')});
`;

const TextareaWrapper = styled.div<{ isOpen: boolean; height: number }>`
  margin-top: 0.5rem;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s ease-in-out,
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: ${({ isOpen, height }) => (isOpen ? height : 0)}px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

const CustomizeButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  color: ${Color.DARK_GRAY};
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
`;
